import type { ProcedureType } from 'types/procedures'
import type { ProviderType } from 'types/providers'
import type { RoomType } from 'types/rooms'

import { getName } from 'utils/text'

export enum ScheduleFields {
  PROCEDURE_TYPES = 'procedureTypes',
  ROOM_IDS = 'roomIds',
  PROVIDER_IDS = 'providerIds',
  HAS_STEM_CELLS = 'hasStemCells',
}

//Convert data into options
export const convertToOptions = (
  data: Array<ProcedureType | RoomType | ProviderType>,
) =>
  data?.map(
    option =>
      ({
        value: option.id,
        label: 'name' in option ? option?.name : getName(option),
      }) ?? [],
  )
