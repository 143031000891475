import {
  CalendarIcon,
  ListBulletIcon,
  MixerHorizontalIcon,
} from '@radix-ui/react-icons'
import {
  alert,
  Button,
  DatePicker,
  Flex,
  Label,
  Pagination,
  Select,
  Text,
} from '@weareredlight/design-system'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { OrganizationAppointmentsParams } from 'types/appointments'
import type { OptionType } from 'types/types'

import api from 'api/api'
import useDateParams from 'hooks/useDateParams'
import { useRequest } from 'hooks/useRequest'
import { CalendarViews, ScheduleViews } from 'utils/enums'
import { enum2SelectOptions } from 'utils/forms'
import { calendarViewLabels } from 'utils/labels'
import { format } from 'utils/time'

type ScheduleNavigationType = {
  organizationId: string
  scheduleView: string
  calendarView: string
  updateCalendarView: React.Dispatch<React.SetStateAction<string>>
  customDate: string
  setCustomDate: (date: string) => void
  showFilters: boolean
  getShowFilters: React.Dispatch<React.SetStateAction<boolean>>
  showList: boolean
  getShowList: React.Dispatch<React.SetStateAction<boolean>>
  calendarFilters?: OrganizationAppointmentsParams
}

const XLS_SIGNATURES = [0x50, 0x4b]

const ScheduleNavigation = ({
  scheduleView,
  calendarView,
  updateCalendarView,
  customDate,
  setCustomDate,
  showFilters,
  getShowFilters,
  showList,
  getShowList,
  calendarFilters,
  organizationId,
}: ScheduleNavigationType) => {
  const { t } = useTranslation()
  const {
    currentDay,
    currentWeek,
    daysInYear,
    weeksInYear,
    isCurrentWeek,
    firstDayOfWeek,
    lastDayOfWeek,
    lastDayOfWeekYear,
  } = useDateParams(customDate)

  const { doRequest: exportAppointments, isLoading: isExporting } = useRequest<
    ArrayBuffer,
    { params?: OrganizationAppointmentsParams }
  >(api.exportAppointments, {
    onSuccess: data => {
      const isXls = new Uint8Array(data.slice(0, 2)).every((byte, i) => {
        return byte === XLS_SIGNATURES[i]
      })

      const blob = new Blob([data], {
        type: isXls
          ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
          : 'text/csv;charset=utf-8;',
      })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute(
        'download',
        `${t('appointmentsExport')}.${isXls ? 'xlsx' : 'csv'}`,
      )
      link.click()
      alert.success(t('Appointments exported successfully'))
    },
    onError: () => {
      alert.error(t('Error exporting appointments'))
    },
  })

  const isDailyView = useMemo(() => {
    return (
      scheduleView === ScheduleViews.CALENDAR &&
      calendarView === CalendarViews.DAY
    )
  }, [scheduleView, calendarView])

  const handleNavigation = useCallback(
    (value: number) => {
      const newDate = isDailyView
        ? dayjs(customDate).dayOfYear(value).toJSON()
        : dayjs(customDate).week(value).toJSON()
      setCustomDate(newDate)
    },
    [customDate, isDailyView, setCustomDate],
  )

  return (
    <Flex
      align="end"
      justify="spaceBetween"
      gap="xsm"
      wrap
      css={{ width: '100%' }}
    >
      {/* Left content */}
      <Flex gap="xxsm">
        <Pagination
          variant="minimal"
          currentPage={isDailyView ? currentDay : currentWeek}
          totalPages={isDailyView ? daysInYear : weeksInYear}
          onPageChange={handleNavigation}
        />
        <Flex gap="xxsm">
          <Text variant="h3">{`${
            isDailyView
              ? `${t(format(customDate, 'dddd'))} ${format(
                  customDate,
                  'MM/DD',
                )}`
              : firstDayOfWeek + ' - ' + lastDayOfWeek
          }`}</Text>
          <Text variant="paragraph" color="accent">{`${lastDayOfWeekYear}, ${t(
            'week',
          )} ${currentWeek}`}</Text>
          {isCurrentWeek && (
            <Text variant="textBlock" color="neutral400">
              - {t('This week')}
            </Text>
          )}
        </Flex>
      </Flex>
      {/* Right content */}
      <Flex gap="xsm" align="end" justify="center">
        <Flex gap="xxxsm" direction="column" align="start">
          <Label label={`${t('Jump to')}:`} />
          <Flex gap="xxxsm">
            <Button
              variant="tertiary"
              disabled={isCurrentWeek}
              onClick={() => setCustomDate(dayjs().toJSON())}
            >
              {t('This week')}
            </Button>
            <DatePicker
              name="date"
              value={customDate}
              onChange={date => setCustomDate(String(date))}
              isWeekSelector={calendarView === CalendarViews.WORK_WEEK}
            />
          </Flex>
        </Flex>
        {scheduleView === ScheduleViews.CALENDAR && (
          <Select
            id="calendar-view"
            label={`${t('View')}:`}
            placeholder=""
            getLabel={(option: OptionType) => t(option.label)}
            getValue={(option: OptionType) => option.value}
            options={enum2SelectOptions(CalendarViews, calendarViewLabels)}
            value={calendarView}
            onChange={e => updateCalendarView(e.target.value)}
            css={{
              button: {
                minWidth: 'unset',
                width: 124,
              },
            }}
          />
        )}
        {scheduleView === ScheduleViews.CALENDAR && (
          <Flex gap="xxxsm">
            <Button
              variant="secondary"
              iconComponent={() =>
                (showList ? <CalendarIcon /> : <ListBulletIcon />)
              }
              iconPosition="iconOnly"
              onClick={() => {
                getShowList(!showList)
              }}
            />
            <Button
              isLoading={isExporting}
              onClick={() =>
                exportAppointments({
                  params: {
                    ...calendarFilters,
                    organizationId,
                    startDateTime: isDailyView
                      ? dayjs(customDate).format('YYYY-MM-DDT00:00:00')
                      : dayjs(customDate)
                          .startOf('w')
                          .format('YYYY-MM-DDT00:00:00'),
                    endDateTime: isDailyView
                      ? dayjs(customDate).format('YYYY-MM-DDT23:59:59')
                      : dayjs(customDate)
                          .endOf('w')
                          .format('YYYY-MM-DDT23:59:59'),
                  } as OrganizationAppointmentsParams,
                })
              }
            >
              {t('Export')}
            </Button>
            <Button
              variant="secondary"
              iconComponent={() => <MixerHorizontalIcon />}
              iconPosition="iconOnly"
              onClick={() => {
                getShowFilters(!showFilters)
              }}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default ScheduleNavigation
